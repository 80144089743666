'use client'

import { Button } from '../components/input/Button/Button'
import { Listbox } from '../components/input/Listbox/Listbox'
import { Card } from '../components/layout/Card/Card'
import { Dialog } from '../components/layout/Dialog/Dialog'
import { useRouter } from '../i18n/Navigation'
import { useTranslation } from '../i18n/useTranslation'
import { useSettingsStore } from '../store/settings'

type SettingsDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
}

export const SettingsDialog = ({ open, setOpen }: SettingsDialogProps) => {
  const { t, locale } = useTranslation()
  const { switchLocale } = useRouter()

  const { settings, setSetting } = useSettingsStore()

  return (
    <Dialog open={open} setOpen={setOpen}>
      {closeDialog => (
        <Card className='flex flex-col gap-4'>
          <h2 className='mb-2 font-bold'>{t.settings.title}</h2>

          <div className='flex flex-col gap-2'>
            <Listbox
              label={t.settings.language}
              className='flex-1'
              options={[
                { label: 'Deutsch', id: 'de' },
                { label: 'English', id: 'en' },
              ]}
              value={settings.language}
              onChange={val => setSetting('language', val as 'en' | 'de')}
            />
            <Listbox
              label={t.settings.theme}
              className='flex-1'
              options={[
                { label: t.settings.theme_system, id: 'system' },
                { label: t.settings.theme_dark, id: 'dark' },
                { label: t.settings.theme_light, id: 'light' },
              ]}
              value={settings.theme}
              onChange={val => setSetting('theme', val as 'system' | 'dark' | 'light')}
            />
          </div>

          <Dialog.Buttons>
            <Button variant='text' onClick={closeDialog}>
              {t.general.cancel}
            </Button>
            <Button
              onClick={() => {
                if (locale != settings.language && settings.language != null) {
                  switchLocale(settings.language)
                  document.documentElement.setAttribute('lang', settings.language)
                }
                closeDialog()
              }}
            >
              {t.general.save}
            </Button>
          </Dialog.Buttons>
        </Card>
      )}
    </Dialog>
  )
}
