'use client'

import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa6'
import { twMerge } from 'tailwind-merge'

import { InfoIcon } from '../../output/InfoIcon/InfoIcon'

type AccordionProps = {
  readonly disabled?: boolean
  readonly active?: boolean
  readonly icon?: JSX.Element
  readonly title: string
  readonly right?: React.ReactNode
  readonly className?: string
  readonly info?: string
  readonly children: JSX.Element
}

export const Accordion = ({
  disabled,
  active,
  icon,
  title,
  right,
  className,
  info,
  children,
}: AccordionProps) => {
  const [open, setOpen] = useState(active)

  return (
    <>
      <div className='flex gap-2'>
        <button
          aria-label={title}
          type='button'
          className={twMerge(
            'group flex w-full cursor-pointer flex-row items-center gap-4 px-4 py-3 hover:bg-white/[0.07]',
            !open && active && 'bg-white/[0.07]',
            disabled && 'pointer-events-none opacity-70 hover:bg-transparent',
            className,
          )}
          onClick={() => setOpen(!open)}
        >
          {icon}
          {title}
          {info && <InfoIcon anchor='start' direction='top' variant='outlined' label={info} />}
          <FaChevronDown
            className={twMerge(
              open && 'rotate-180 transform',
              'ml-auto text-sm transition-transform ',
              disabled && 'group-hover:opacity-0',
            )}
          />
        </button>
        {right}
      </div>
      <AnimatePresence initial={false}>
        {open && (
          <motion.div
            className='overflow-hidden'
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: 'auto',
              opacity: 1,
              transition: {
                height: { duration: 0.3 },
                opacity: { duration: 0.2, delay: 0.1 },
              },
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: {
                height: { duration: 0.3 },
                opacity: { duration: 0.1 },
              },
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
