'use client'

import { twMerge } from 'tailwind-merge'

import { Accordion } from '../Accordion/Accordion'

import { NavigationLink } from './NavigationLink'

type NavigationItemsProps = {
  readonly disabled?: boolean
  readonly menuItems: readonly {
    readonly name: string
    readonly href?: string
    readonly baseUrl?: string
    readonly icon: JSX.Element
    readonly active?: boolean
    readonly className?: string
    readonly childItems?: readonly {
      readonly name: string
      readonly href: string
      readonly baseUrl?: string
      readonly icon?: JSX.Element
    }[]
  }[]
  readonly onNavigation: () => void
  readonly className?: string
}

export const NavigationItems = ({
  disabled,
  menuItems,
  onNavigation,
  className,
}: NavigationItemsProps) => {
  return (
    <ul
      className={twMerge('flex flex-col text-regular-dark md:block', className)}
      data-testid='menu-links'
    >
      {menuItems.map(
        ({ icon, name, href, baseUrl, active, className: itemClassName, childItems }) => (
          <li key={name}>
            {childItems && childItems.length > 0 ? (
              <Accordion icon={icon} title={name} active={active} disabled={disabled}>
                <ul>
                  {childItems?.map(childItem => (
                    <li key={childItem.href}>
                      <NavigationLink
                        className='ml-8'
                        {...childItem}
                        disabled={disabled}
                        onNavigation={onNavigation}
                      />
                    </li>
                  ))}
                </ul>
              </Accordion>
            ) : (
              <NavigationLink
                className={itemClassName}
                name={name}
                href={href}
                baseUrl={baseUrl}
                icon={icon}
                disabled={disabled}
                onNavigation={onNavigation}
              />
            )}
          </li>
        ),
      )}
    </ul>
  )
}
