'use client'

import { Menu, Transition } from '@headlessui/react'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import {
  Component,
  Fragment,
  type MutableRefObject,
  type ReactNode,
  createRef,
  useCallback,
  useRef,
  useState,
} from 'react'
import { FaBars, FaChevronRight, FaUser } from 'react-icons/fa6'
import { twMerge } from 'tailwind-merge'

import { type Group } from '../../../hooks/useHighestGroup'
import { Link } from '../../../i18n/Navigation'
import { useTranslation } from '../../../i18n/useTranslation'
import { type LocalUser } from '../../../store/user'
import { Button } from '../../input/Button/Button'
import { Skeleton } from '../../output/Skeleton/Skeleton'
import { NavigationItems } from '../NavigationItems/NavigationItems'

import { PageTitleContext } from './PageTitle'

class PageTitle extends Component {
  private pageTitleContent: MutableRefObject<ReactNode> = createRef()

  setContent(content: ReactNode) {
    this.pageTitleContent.current = content
    // Force update after the current render cycle
    setTimeout(() => this.forceUpdate(), 0)
  }

  render() {
    return <div className='flex flex-row flex-wrap gap-x-2'>{this.pageTitleContent.current}</div>
  }
}

type MainNavigationProps = {
  readonly menuItems: readonly {
    readonly name: string
    readonly href?: string
    readonly baseUrl?: string
    readonly icon: JSX.Element
    readonly active?: boolean
    readonly className?: string
    readonly childItems?: readonly {
      readonly name: string
      readonly href: string
      readonly baseUrl?: string
      readonly icon?: JSX.Element
    }[]
  }[]
  readonly version?: string
  readonly children: React.ReactNode
  readonly user: LocalUser
  readonly role?: Group
  readonly admin?: boolean
  readonly signOutUser?: () => void
  readonly openSettingsMenu?: () => void
}

export const MainLayout = ({
  menuItems,
  children,
  user,
  role,
  admin,
  signOutUser,
  openSettingsMenu,
}: MainNavigationProps) => {
  const { t } = useTranslation()

  const mainContentRef = useRef<HTMLDivElement>(null)
  const pageTitleRef = useRef<PageTitle>(null)

  const [menuOpen, setMenuOpen] = useState(false)

  const pathname = usePathname()

  const skipToContent = useCallback(() => {
    const mainContent = mainContentRef.current

    if (mainContent) {
      mainContent.tabIndex = 0
      mainContent.focus()
    }
  }, [])

  const onLoginPageAndSignedOut = /^\/\w+\/login$/.test(pathname) && user.id == null
  const onAdminPage = /\/admin/.test(pathname)

  const profileBtn = user.loading ? (
    <Skeleton className='h-10 w-10 rounded-full' />
  ) : (
    user.id != null && (
      <>
        <Menu as='div' className='relative inline-block text-left'>
          <div>
            <Menu.Button
              aria-label={t.general.profileMenuBtn}
              className='flex h-10 w-10 items-center justify-center rounded-full bg-gray-300 dark:bg-gray-500'
              data-testid='profile-menu'
            >
              <FaUser />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 z-[1] mt-2 w-56 origin-top-right rounded-md bg-foreground p-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-gray-600'>
              {(role?.group === 'admin' || role?.group === 'projectmanager') && (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      href={onAdminPage ? '/' : '/admin'}
                      className={twMerge(
                        active && 'bg-hover',
                        'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                      )}
                      data-testid='profile-menu-admin'
                    >
                      {onAdminPage ? t.settings.leaveAdminPanel : t.settings.enterAdminPanel}
                    </Link>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={twMerge(
                      active && 'bg-hover',
                      'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                    )}
                    onClick={() => openSettingsMenu?.()}
                    data-testid='profile-menu-settings'
                  >
                    {t.settings.title}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={twMerge(
                      'group flex w-full items-center rounded-md px-2 py-2 text-sm text-error-600',
                      active && 'bg-error text-error',
                    )}
                    onClick={signOutUser}
                  >
                    {t.settings.logout}
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    )
  )

  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  return (
    <div className='z-0 flex h-full w-full flex-col md:flex-row'>
      <nav
        className={twMerge(
          'flex w-full shrink-0 grow-0 flex-col gap-8 bg-primary-light shadow-md md:w-64',
          admin && 'border-l-4 border-error',
        )}
      >
        <div className='flex flex-col'>
          <button
            className='h-0 overflow-hidden text-regular-dark focus:h-auto'
            onClick={skipToContent}
            data-testid='skip-button'
          >
            <p className='m-4'>{t.navigation.skipNavigation}</p>
          </button>
          <div className='my-3 flex flex-row items-center justify-between gap-5 px-3 md:my-6 md:justify-start'>
            <Button
              icon
              variant='text'
              className='max-md:h-8 max-md:w-8 max-md:p-0 md:hidden'
              onClick={() => setMenuOpen(s => !s)}
              aria-label={menuOpen ? 'Navigation schließen' : 'Navigation öffnen'}
              data-testid='menu-button'
            >
              <FaBars className='text-3xl text-regular-dark' />
            </Button>

            <h1>
              <Image src='/logo.svg' alt='Funke Service Portal' width={140} height={50} priority />
            </h1>

            <div className='md:hidden'>{profileBtn}</div>
          </div>
        </div>

        <div
          className={twMerge(
            'absolute top-[63px] z-10 flex h-[calc(100%-63px)] w-full flex-grow flex-col justify-between gap-6 overflow-y-auto bg-primary-light pt-6 transition-[left] md:static md:mt-0 md:pt-0',
            menuOpen ? 'left-0' : '-left-full',
          )}
        >
          <NavigationItems
            menuItems={menuItems.map(item =>
              item.childItems
                ? {
                    ...item,
                    active: item.childItems?.some(childItem => pathname.includes(childItem.href)),
                  }
                : item,
            )}
            disabled={onLoginPageAndSignedOut}
            onNavigation={() => setMenuOpen(false)}
          />

          <div className='p-2 text-sm text-regular-dark'>
            <div className='mb-4 flex flex-row justify-center gap-2'>
              <div className='underline'>
                <Link
                  href='https://funkemediasales.de/agb/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t.navigation.termsOfService}
                </Link>
              </div>
              •
              <div className='underline'>
                <Link
                  href='https://www.funkemedien.de/de/datenschutzerklaerung/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t.navigation.privacyPolicy}
                </Link>
              </div>
              •
              <div className='underline'>
                <Link
                  href='https://www.funkemedien.de/de/impressum'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t.navigation.imprint}
                </Link>
              </div>
            </div>

            <p className='text-center opacity-70'>
              {t.navigation.version(process.env.EVA_VERSION ?? 'unknown')}
            </p>
          </div>
        </div>
      </nav>
      <main className='flex min-w-0 flex-grow flex-col overflow-auto md:overflow-visible'>
        <div className='flex min-h-[74px] shrink-0 flex-row items-center justify-between border-b-2 border-lighter p-4'>
          <PageTitle ref={pageTitleRef} />

          {!onLoginPageAndSignedOut && (
            <div className='hidden flex-row gap-4 md:flex'>
              <div className='flex flex-col items-end justify-center'>
                <p className='whitespace-nowrap text-right text-sm'>
                  {user.loading ? <Skeleton className='w-32' /> : user.name}
                </p>
                {(user.loading || role?.label) && (
                  <p className='whitespace-nowrap text-right text-sm opacity-75'>
                    {user.loading ? <Skeleton className='w-48' /> : role?.label}
                  </p>
                )}
              </div>

              {profileBtn}
            </div>
          )}
        </div>
        <div
          className='flex-grow [scrollbar-gutter:stable] md:overflow-auto'
          data-testid='main-content'
          ref={mainContentRef}
        >
          <div className='p-2 md:p-4'>
            <PageTitleContext.Provider
              value={({ title: pageTitle, breadcrumbs }) => {
                if (!pageTitleRef.current) return

                pageTitleRef.current.setContent(
                  <>
                    {breadcrumbs.length > 0 && (
                      <div className='flex flex-row gap-1'>
                        {breadcrumbs.map(({ title, page }) => (
                          <div key={page} className='flex flex-row items-center gap-1'>
                            <Link href={page} className='text-sm opacity-75 hover:opacity-100'>
                              {title ? <span>{title}</span> : <Skeleton className='w-48' />}
                            </Link>
                            <FaChevronRight className='text-xs opacity-50' aria-hidden='true' />
                          </div>
                        ))}
                      </div>
                    )}
                    <h2 className='text-2xl'>
                      {!pageTitle ? <Skeleton className='w-48' /> : <span>{pageTitle}</span>}
                    </h2>
                  </>,
                )
              }}
            >
              {children}
            </PageTitleContext.Provider>
          </div>
        </div>
      </main>
    </div>
  )
}
