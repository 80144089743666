export type ResourceType =
  | 'clusters'
  | 'containers'
  | 'container-git'
  | 'backup-policies'
  | 'disk-snapshots'
  | 'project-quota-requests'
  | 'networks'
  | 'network-service-policies'
  | 'apps'
  | 'secrets'
  | 'configurations'

export const isFeatureEnabled = (resourceType: ResourceType) =>
  !process.env.DISABLE_FEATURES?.split(',').some(feat => feat === resourceType)
