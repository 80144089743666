'use client'

import { usePathname } from 'next/navigation'
import { twMerge } from 'tailwind-merge'

import { Link } from '../../../i18n/Navigation'

type NavigationLinkProps = {
  readonly className?: string
  readonly disabled?: boolean
  readonly name: string
  readonly href?: string
  readonly baseUrl?: string
  readonly icon?: JSX.Element
  readonly onNavigation: () => void
}

export const NavigationLink = ({
  className,
  disabled,
  href,
  baseUrl,
  name,
  icon,
  onNavigation,
}: NavigationLinkProps) => {
  const pathname = usePathname()
  const filteredPath = pathname?.replace(new RegExp(`^\/\\w+${baseUrl ?? ''}`), '') || '/'

  return (
    <>
      {href ? (
        <Link
          href={baseUrl ? baseUrl + href : href}
          className={twMerge(
            'group flex cursor-pointer flex-row items-center gap-4 px-4 py-3 hover:bg-white/[0.07]',
            (href === '/' ? href === filteredPath : filteredPath.startsWith(href)) &&
              'bg-white/[0.07]',
            disabled && 'pointer-events-none opacity-70 hover:bg-transparent',
            className,
          )}
          onClick={onNavigation}
        >
          {icon}
          {name}
        </Link>
      ) : (
        <div
          className={twMerge(
            'group flex cursor-pointer flex-row items-center gap-4 px-4 py-3 hover:bg-white/[0.07]',
            disabled && 'pointer-events-none opacity-70 hover:bg-transparent',
            className,
          )}
        >
          {icon}
          {name}
        </div>
      )}
    </>
  )
}
