import { useTranslation } from '../i18n/useTranslation'
import { useUserStore } from '../store/user'

export type Group = Partial<{
  group: 'admin' | 'projectmanager' | 'dev'
  label: string
}>

/**
 * Returns the highest group of the user with a fitting label
 */
export const useHighestGroup = (): Group => {
  const { t } = useTranslation()
  const { user } = useUserStore()

  if (user.loading || !user.groups) return {}

  const groups = user.groups

  const isAdmin = groups.some(g => g.endsWith('-admin'))
  const isProjectManager = groups.some(g => g.endsWith('-projectmanager'))

  return {
    group: isAdmin ? 'admin' : isProjectManager ? 'projectmanager' : 'dev',
    label: isAdmin
      ? t.general.groups.admin
      : isProjectManager
      ? t.general.groups.projectManager
      : t.general.groups.dev,
  }
}
