'use client'

import { AnimatePresence, motion } from 'framer-motion'
import { type ReactElement, type ReactNode, cloneElement, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'
import { twMerge } from 'tailwind-merge'

import { renderFunctionOrElement } from '../../../helpers/renderFunctionOrElement'
import { useIsSsr } from '../../../hooks/useIsSsr'

const backdropVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    ease: 'easeInOut',
    duration: 0.3,
  },
}

const dialogVariants = {
  initial: {
    opacity: 0,
    scale: 0.85,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.85,
  },
  transition: {
    ease: 'easeInOut',
    duration: 0.3,
  },
}

type DialogProps = {
  readonly children: ReactNode | ((close: () => void) => ReactNode)
  readonly size?: 'sm' | 'md' | 'lg' | 'xl'
  readonly trigger?: ReactElement | ((open: () => void) => ReactElement)
  readonly open?: boolean
  readonly setOpen?: (open: boolean) => void
}

const Dialog = ({ children, size = 'sm', trigger, open, setOpen }: DialogProps) => {
  const [isOpen, setIsOpen] = useState(open ?? false)
  const isSsr = useIsSsr()

  useEffect(() => {
    setOpen?.(isOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    setIsOpen(open ?? false)
  }, [open])

  return (
    <>
      {trigger &&
        (typeof trigger === 'function'
          ? trigger(() => setIsOpen(true))
          : cloneElement(trigger as ReactElement, {
              onClick: () => {
                setIsOpen(true)
                ;(trigger as ReactElement).props.onClick?.()
              },
            }))}

      {!isSsr &&
        createPortal(
          <AnimatePresence initial={false}>
            {isOpen && (
              <div className='relative z-10'>
                <motion.div
                  className='fixed inset-0 bg-black bg-opacity-25'
                  onTap={() => setIsOpen(false)}
                  {...backdropVariants}
                />

                <div className='pointer-events-none fixed inset-0 overflow-y-auto'>
                  <div className='pointer-events-none flex min-h-full items-center justify-center p-2'>
                    <motion.div
                      onKeyDown={e => e.key === 'Escape' && setIsOpen(false)}
                      className={twMerge(
                        'pointer-events-auto w-full',
                        size === 'sm'
                          ? 'max-w-2xl'
                          : size === 'md'
                          ? 'max-w-3xl'
                          : size === 'lg'
                          ? 'max-w-4xl'
                          : 'max-w-5xl',
                      )}
                      {...dialogVariants}
                    >
                      <FocusLock>
                        {renderFunctionOrElement(children, () => setIsOpen(false))}
                      </FocusLock>
                    </motion.div>
                  </div>
                </div>
              </div>
            )}
          </AnimatePresence>,
          document?.body,
        )}
    </>
  )
}

type DialogButtonsContainerProps = {
  readonly children: ReactNode
}

export const DialogButtonsContainer = ({ children }: DialogButtonsContainerProps) => (
  <div className='mt-6 flex justify-end gap-6'>{children}</div>
)

Dialog.Buttons = DialogButtonsContainer

export { Dialog }
