import {
  FaBox,
  FaBoxesStacked,
  FaCalendarDay,
  FaCameraRetro,
  FaCircleNodes,
  FaClockRotateLeft,
  FaCommentMedical,
  FaDiagramProject,
  FaFloppyDisk,
  FaGaugeHigh,
  FaGitAlt,
  FaGrip,
  FaHardDrive,
  FaHouse,
  FaImages,
  FaKey,
  FaMemory,
  FaMicrochip,
  FaServer,
  FaSliders,
  FaStore,
  FaVrCardboard,
} from 'react-icons/fa6'

// Navigation
export const DashboardIcon = FaHouse
export const ComputingIcon = FaServer
export const StorageIcon = FaFloppyDisk
export const AppsIcon = FaGrip

// Resources
export const ProjectIcon = FaCalendarDay
export const ClusterIcon = FaCircleNodes
export const ConfigurationIcon = FaSliders
export const SecretIcon = FaKey
export const AppStoreIcon = FaStore
export const InstalledAppsIcon = FaBoxesStacked
export const ContainerIcon = FaBox
export const VirtualMachineIcon = FaVrCardboard
export const DiskIcon = FaHardDrive
export const ImageIcon = FaImages
export const DiskSnapshotIcon = FaCameraRetro
export const BackupPolicyIcon = FaClockRotateLeft
export const NetworkIcon = FaDiagramProject
export const ProjectQuotaRequestIcon = FaCommentMedical

//Virtual Machines
export const CpuIcon = FaMicrochip
export const MemoryIcon = FaMemory

// Disks
export const SizeGibIcon = FaHardDrive
export const IopsIcon = FaGaugeHigh

// Image Fallback Icons
export const AppImageFallbackIcon = FaGitAlt
